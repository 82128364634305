@use '../abstracts/colors';
@use '../base/animations';

.btnCnt {
	display: flex;
}
.btnPrimary {
	&:link,
	&:visited {
		display: inline-block;
		font-size: 1.6rem;
		padding: 2rem 4rem;
		background-color: colors.$color-primary;
		color: colors.$color-white;
		cursor: pointer;
		letter-spacing: 0.3rem;
		animation: moveInBottom 1s ease-out;
		transition: all 0.3s;
	}
	&:active {
		box-shadow: 0 0.5rem 1rem rgba(colors.$color-black, 0.15);
		transform: translateY(5px);
	}
	&:hover {
		transform: translateY(-5px);
		background-color: colors.$color-primary-dark;
		box-shadow: 0 1rem 2rem rgba(colors.$color-black, 0.15);
	}
}

.btnSecondary {
	border-radius: 0.25rem;
	@mixin btnSecondaryHover {
		transform: translateY(-5px);
        background-color: white;
	}
	&:link,
	&:visited {
		display: inline-block;
		font-size: 1.6rem;
		padding: 2rem 4rem;
		background-color: transparent;
		color: colors.$color-grey-dark;
		cursor: pointer;
		letter-spacing: 0.3rem;
		animation: moveInBottom 1s ease-in-out;
		transition: all 0.3s;
		box-shadow: 0 1.5rem 4rem rgba(colors.$color-black, .15);
		border: 2px solid colors.$color-grey-dark;
	}
	&:active {
		box-shadow: 0 0.5rem 1rem rgba(colors.$color-black, 0.15);
		transform: translateY(5px);
	}
	&:hover {
		@include btnSecondaryHover;
	}
	&Inverse {
		&:link,
		&:visited {
			color: colors.$color-black;
			border: 1px solid colors.$color-black;
		}
		&:hover {
			@include btnSecondaryHover;
		}
	}
}
