@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';

.mission {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 7rem;
	margin-bottom: 7rem;
	gap: 6rem;
	margin-left: 2rem;
	margin-right: 2rem;

	&Title {
		color: colors.$color-grey-dark;
		font-size: 2rem;
		font-weight: 400;
		letter-spacing: 0.2rem;
		font-family: 'Lusitana', serif;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	&Description {
		font-size: 1.2rem;
		font-weight: 400;
		letter-spacing: 0.1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		&Container {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
}

// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.mission {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 7rem;
		margin-bottom: 7rem;

		&Title {
			font-size: 2.8rem;
			font-weight: 400;
			letter-spacing: 0.2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

		&Description {
			font-size: 1.6rem;
			font-weight: 400;
			letter-spacing: 0.2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			&Container {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				width: 33.3333%;
			}
		}
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
}
