@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';
@use '../components/hero-img';

.hero {
	&Text {
		position: absolute;
		top: 5%;
		text-align: center;
		color: colors.$color-grey-dark;
		height: 100%;
		display: flex;
		flex-direction: column;
		z-index: 2;
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 24em) {}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {}

// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.hero {
		&Text {
			top: 40%;
			left: 50%;
			text-align: left;
			font-size: 6rem;
			z-index: 2;
		}
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {}