@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';
@use '../components/button';

.about {
	display: flex;
	flex-direction: column;
	background-color: colors.$color-grey-light;

	&Picture {
		&Side {
			height: 100%;
			width: 100%;
			clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%);
		}

		&Container {
			height: 42rem;
			position: relative;
		}
	}

	&Description {
		&Text {
			width: 66.6667%;
			font-size: 1.6rem;
			text-align: center;
		}

		&Container {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding-top: 4rem;
			padding-bottom: 4rem;
			padding-left: 1rem;
			padding-right: 1rem;
			gap: 6rem;
		}
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {}

// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.about {
		height: 54rem;
		flex-direction: row;

		&Picture {
			&Side {
				height: 100%;
				width: 50%;
				clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
			}

			&Container {
				height: 100%;
			}
		}

		&Description {
			&Container {
				width: 60%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 6rem;
			}
		}
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {}