@use '../abstracts/colors';
@use '../base/typography';
@use '../base/animations';
@use '../base/utilities';

.articles {
	&MarkContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6rem;
	}

	&CardContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 4rem;
		margin: 2rem;
	}

	&Section {
		background-color: colors.$color-grey-light;
		padding: 4rem 0;
		display: flex;
		flex-direction: column;
		gap: 4rem;
	}

	&HeadingSecondary {
		font-size: 2rem;
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: 0.2rem;

		&Container {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 6rem;

		}
	}

	&SeeAll {
		color: colors.$color-grey-dark;
		font-size: 1.4rem;
		letter-spacing: 0.1rem;
		margin: 0 2rem;
		display: flex;
		flex-direction: column;
		text-align: end;
		cursor: pointer;
		position: relative;
		flex-direction: column;

		&Text {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		&:hover ion-icon {
			transition: all 0.3s ease-in;
			transform: rotate(180deg);
		}

		& ion-icon {
			transition: all 0.3s ease-out;
		}
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {}

// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
	.articles {
		&CardContainer {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			place-items: center;
			gap: 4rem;
			margin: 4rem;
		}
	}
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.articles {
		&SeeAll {
			padding-left: 6rem;
			padding-right: 6rem;
		}
	}
}

// ABOVE 1600px (For large desktops)
@media only screen and (min-width: 100em) {
	.articles {
		&CardContainer {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			place-items: center;
			gap: 4rem;
			margin: 4rem;
		}
	}
}

// ABOVE 1984px (For extra large desktops)
@media only screen and (min-width: 124em) {
	.articles {
		&CardContainer {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}